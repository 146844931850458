import { Link } from "@StarberryUtils";
import * as React from "react"
import HTMLReactParser from 'html-react-parser';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


import PlayVideo from "../../Play/custom-video";
import { Button, Container, Row, Form } from 'react-bootstrap';
import CareerFormPage from "../../forms/career-form";
import ContactFormPage from "../../forms/contact-form";
import ValuationFormPage from "../../forms/valuation-form";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
// Images
import Google from "../../../images/google-reviews.png"

// styles
//import ScrollAnimation from "react-animate-on-scroll";
import "./Banner.scss"
// markup

const FormHeader = (props) => {
  let [isPlay, setPlay] = React.useState(false);
  let url_1 = ''
  let url_2 = ''

  const image_url = props.image.url

	let processedImages = JSON.stringify({});
	if (props.imagetransforms?.Banner_Image_Transforms) {
		processedImages = props.imagetransforms?.Banner_Image_Transforms;
	}
  return (
    <React.Fragment>
      <div className={`main-banner`}>
        <div className="main-banner-embed background-shadow d-xl-flex d-none align-items-xl-stretch"> 
          <ImageTransform imagesources={image_url} renderer="srcSet" imagename="articles.Banner_Image.details" attr={{ alt: `${props.image.alternativeText} - Dacha`, class: 'bg-img' }} imagetransformresult={processedImages} id={props.id} />
          {props.video &&
            <div className="banner-btn">
              <PlayVideo url={props.video}
              />
            </div>
          }
        </div>
        <div className="main-banner-content form-header">
          <Container>
            <div className="form-banner-text">
              {props.modules && props.modules.map((module, i) => {
                return (
                  <>
                    {HTMLReactParser(module.Title.replace(/\n/g, '<br />'))}
                    {module.Name === "Career_Form" &&
                      <CareerFormPage />
                    }
                    {module.Name === "Contact_Form" &&
                      <ContactFormPage />
                    } 
                  </>
                )
              })}
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FormHeader