import React, { useState, useEffect, PropTypes } from "react"
import { useMatch } from "@reach/router"
import { Button, Container, Row, Form } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import Homepage from "../components/Home/Banner/Banner";
import PlainHeader from "../components/Home/Banner/PlainHeader";
import NewsHeader from "../components/Home/Banner/NewsHeader";
import FormHeader from "../components/Home/Banner/FormHeader";
import VideosHeader from "../components/Home/Banner/VideosHeader";
import WhiteHeader from "../components/Home/Banner/WhiteHeader";
import StaticBanner from "../components/Home/Banner/StaticBanner";

import { LATEST_NEWS_PAGE_URL, MEET_THE_TEAM_PAGE_URL, VIDEOS_PAGE_URL } from "../components/common/site/constants";
import Loadable from "@loadable/component"

const Foundation = Loadable(() => import("../components/Home/Foundation/Foundation"))
const LatestNews = Loadable(() => import("../components/Home/News/LatestNews"))
const AllNews = Loadable(() => import("../components/Home/News/AllNews"))
const NewsDetail = Loadable(() => import("../components/Home/News/NewsDetail"))
const News = Loadable(() => import("../components/Home/News/News"))
const Videos = Loadable(() => import("../components/Home/Videos/Videos"))
const Counter = Loadable(() => import("../components/Home/Counter/Counter"))
const SocialMedia = Loadable(() => import("../components/Home/Counter/SocialMedia"))
const ServiceTiles = Loadable(() => import("../components/Home/ServiceTiles/ServiceTiles"))
const ImageServiceCards = Loadable(() => import("../components/Home/ServiceCards/ImageServiceCards"))
const ServiceCards = Loadable(() => import("../components/Home/ServiceCards/ServiceCards"))
const Information = Loadable(() => import("../components/Home/Information/Information"))
const InformationList = Loadable(() => import("../components/Home/Information/InformationList"))
const Sidebar = Loadable(() => import("../components/Sidebar/Sidebar"))
const OurStorySidebar = Loadable(() => import("../components/Sidebar/OurStorySidebar"))
const CareerSidebar = Loadable(() => import("../components/Sidebar/CareerSidebar"))
const ServiceSidebar = Loadable(() => import("../components/Sidebar/ServiceSidebar"))
const PlayVideo = Loadable(() => import("./Play/custom-video"))
const Map = Loadable(() => import("../components/map/google/single"))
const TeamSlider = Loadable(() => import("../components/StaticComponent/OurTeam/OurTeam"))
const Breadcrumb = Loadable(() => import("../components/Home/Breadcrumb/Breadcrumb"))
const AreaGuides = Loadable(() => import("../components/collections/AreaGuides"))
const Peoples = Loadable(() => import("../components/collections/Peoples"))
const PeopleDetail = Loadable(() => import("../components/StaticComponent/Founder/Founder"))
const StaticContact = Loadable(() => import("../components/Home/ContactUs/StaticContact"))
const Milestones = Loadable(() => import("../components/Home/AboutUs/Milestones"))

const About = Loadable(() => import("../components/Home/AboutUs/About"))
const AnimationBlock = Loadable(() => import("../components/Home/AboutUs/AnimationBlock"))
const FeaturedProperties = Loadable(() => import("../components/Home/Properties/Properties"));
const Reviews = Loadable(() => import("../components/Home/Reviews/Reviews"));
const GoogleReviews = Loadable(() => import("../components/PropertyDetails/GoogleReviews/GoogleReviews"));
const Contact = Loadable(() => import("../components/Home/ContactUs/Contact"));


const Modules = (props) => {
    const NewsCategory = useMatch(LATEST_NEWS_PAGE_URL.alias + "/:item/");
    const NewsDetails = useMatch(LATEST_NEWS_PAGE_URL.alias + "/:item/:slug")
    const PeopleDetails = useMatch(MEET_THE_TEAM_PAGE_URL.alias + "/:slug/")
    const VideoCategory = useMatch(VIDEOS_PAGE_URL.alias + "/:item/");

    const [sidebar, setSidebar] = useState(false);
    const [renderComponent, setRenderComponent] = useState(true);
    let language = typeof window !== 'undefined' ? localStorage.getItem('language') : null
    console.log("language", language)
    useEffect(() => {

        window.addEventListener("scroll", () => {
            setSidebar(window.scrollY > 0)

        })

        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })

        if (props.page.Pagename !== "Home") {
            setRenderComponent(true)
        }
    }, [])
    if (NewsDetails !== null) {
        var URL = NewsDetails.slug
        var MAIN_URL = NewsDetails.item
        return (
            <div className={language === 'Russian' ? "russian-page-modules page-modules" : "page-modules"}>
                <NewsDetail detailpage={URL} alias={LATEST_NEWS_PAGE_URL.alias} category={MAIN_URL} site={props.site} timings={props.site.Available_Timings} location={props.location}/>
                <Breadcrumb data={props.page.all_menus ? props.page.all_menus[0] : ''} page={props.page.Pagename} tag="details" category={MAIN_URL} name={URL} />
            </div>
        )
    }
    else {
        return (
            <div className={language === 'Russian' ? "russian-page-modules page-modules" : "page-modules"}>

                {props.page.Header_Content && props.page.Banner_Image && props.page.Layout !== 'Static' && props.page.Header_Style !== "White_Background" &&
                    <Homepage id={props.page._id} imagetransforms={props.page.imagetransforms} content={props.page.Header_Content} image={props.page.Banner_Image} info={props.page.Header_Contact_Info} cta_1={props.page.Header_CTA_1_Label} cta_2={props.page.Header_CTA_2_Label} cta_1_link={props.page.Header_CTA_1_Link} cta_2_link={props.page.Header_CTA_2_Link} search={props.page.Show_Search_Type} rating={props.page.Show_Google_Rating} video={props.page.Video_URL} mobile_video={props.page.Mobile_Video} timings={props.site.Available_Timings} pagename={props.page.Pagename} layout={props.page.Layout} />
                }

                {props.page.Header_Content && props.page.Banner_Image == null && props.page.Layout !== 'Static' && props.page.Layout !== 'News_Landing' && props.page.Header_Style !== "White_Background" && props.page.Layout !== 'Videos_Landing' &&
                    <PlainHeader content={props.page.Header_Content} info={props.page.Header_Contact_Info} cta_1={props.page.Header_CTA_1_Label} cta_2={props.page.Header_CTA_2_Label} cta_1_link={props.page.Header_CTA_1_Link} cta_2_link={props.page.Header_CTA_2_Link} search={props.page.Show_Search_Type} rating={props.page.Show_Google_Rating} video={props.page.Video_URL} page={props.page.Pagename} />
                }

                {props.page.Header_Content && props.page.Banner_Image == null && props.page.Layout !== 'Static' && props.page.Layout === 'News_Landing' && props.page.Header_Style !== "White_Background" &&
                    <NewsHeader content={props.page.Header_Content} category={NewsCategory} />
                }

                {props.page.Header_Content && props.page.Banner_Image == null && props.page.Layout === 'Videos_Landing' && props.page.Header_Style !== "White_Background" &&
                    <VideosHeader content={props.page.Header_Content} category={VideoCategory} />
                }
                {props.page.Banner_Image && props.page.Layout === 'Forms_Landing' && props.page.Header_Style !== "White_Background" &&
                    <FormHeader id={props.page._id} imagetransforms={props.page.imagetransforms} image={props.page.Banner_Image} modules={props.page.Modules} />
                }
                {props.page.Header_Content && props.page.Layout !== 'Static' && props.page.Layout !== 'News_Landing' && props.page.Header_Style === "White_Background" &&
                    <WhiteHeader content={props.page.Header_Content} />
                }



                {props.page.Banner_Image && props.page.Layout === 'Static' && props.page.Header_Style !== "White_Background" &&
                    <StaticBanner id={props.page._id} imagetransforms={props.page.imagetransforms} content={props.page.Header_Content} image={props.page.Banner_Image} info={props.page.Header_Contact_Info} cta_1={props.page.Header_CTA_1_Label} cta_2={props.page.Header_CTA_2_Label} cta_1_link={props.page.Header_CTA_1_Link} cta_2_link={props.page.Header_CTA_2_Link} search={props.page.Show_Search_Type} rating={props.page.Show_Google_Rating} video={props.page.Video_URL} />
                }
                {(props.page.Layout === 'Static' || props.page.Layout === 'Service_Landing' || props.page.Layout === 'Our_Story_Landing' || props.page.Layout === 'Careers_Landing') && props.modules &&
                    <Container className={`static-content-landing pad-50 ${props.page.Banner_Image === null ? 'no-banner' : ''}`}>
                        <Row>
                            <div className={`col-xl-${(props.page.Show_Sidebar) ? '8' : '12'}  plain-content`}>

                                {props.page.Banner_Image === null ? <div className="head-black">{HTMLReactParser(props.page.Header_Content.replace(/\n/g, '<br />'))}</div> : ''}

                                {props.modules && props.modules.map((module, i) => {
                                    return (
                                        <>
                                            {module.Add_Static_Content &&
                                                <>
                                                    {HTMLReactParser(module.Add_Static_Content.replace(/<br\s*[\/]?>/gi, "\n"))}
                                                </>
                                            }

                                            {module.Video_Embed_URL && module.Video_Background_Image &&
                                                <div className="video">
                                                    <picture>
                                                        <img loading="lazy" className="bg-img" src={`${module.Video_Background_Image.url}`} alt={module.Video_Background_Image.alternativeText} />
                                                    </picture>
                                                    {module.Video_Embed_URL &&
                                                        <div className="banner-btn">
                                                            <PlayVideo url={module.Video_Embed_URL} />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {module.Service_Cards && module.Service_Cards[0].Image == null &&
                                                <ServiceCards data={module.Service_Cards} />
                                            }

                                        </>
                                    )
                                })}

                            </div>
                            {props.page.Show_Sidebar === true &&
                                <div className="col-xl-4  col-md-12">
                                    {props.page.Layout === 'Static' && props.page.Useful_Information === false &&
                                        <Sidebar class={`static-sticky-sidebar`} peoples={props.page.Sidebar_Peoples} site={props.site} timings={props.site.Available_Timings} popular_search={props.page.Popular_Search} />
                                    }
                                    {props.page.Layout === 'Service_Landing' && props.page.Useful_Information === false &&
                                        <ServiceSidebar class={`${sidebar ? 'static-sticky-sidebar' : ''}`} peoples={props.page.Sidebar_Peoples} site={props.site} timings={props.site.Available_Timings} />
                                    }
                                    {props.page.Layout === 'Service_Landing' && props.page.Useful_Information === true &&
                                        <Information />
                                    }
                                    {props.page.Layout === 'Our_Story_Landing' && props.page.Useful_Information === false &&
                                        <OurStorySidebar class={`static-sticky-sidebar`} peoples={props.page.Sidebar_Peoples} site={props.site} timings={props.site.Available_Timings} popular_search={props.page.Popular_Search} />
                                    }
                                    {props.page.Layout === 'Careers_Landing' && props.page.Useful_Information === true &&
                                        <CareerSidebar class={`static-sticky-sidebar`} peoples={props.page.Sidebar_Peoples} site={props.site} timings={props.site.Available_Timings} popular_search={props.page.Popular_Search} />
                                    }


                                </div>
                            }
                        </Row>
                    </Container>
                }




                {
                    props.modules && props.modules.map((module, i) => {
                        return (
                            <>
                                {renderComponent && module.Place &&
                                    <FeaturedProperties place={module.Place} search={module.Search_Type} pagename={props.page.Pagename} />
                                }

                                {renderComponent && module.Add_Service &&
                                    <About service={module.Add_Service} id={props.page._id} imagetransforms={props.page.imagetransforms} />

                                }
                                {module.Add_Service && props.page.Layout === 'Service_Landing' && props.page.Useful_Information === true &&
                                    <Container className={`static-content-landing pad-50`}>
                                        <Row>
                                            <div className={`col-xl-12  InformationList`}>
                                                <InformationList />
                                            </div>
                                        </Row>
                                    </Container>

                                }

                                {module.Animation &&
                                    <AnimationBlock {...module} />
                                }
                                {module.Select === 'Vision_Mission_Values' &&
                                    <Foundation />
                                }

                                {renderComponent && module.Select === 'Latest_News_and_Insights' &&
                                    <LatestNews />
                                }

                                {renderComponent && module.type === "GLSTRAPI_ComponentModuleFooterBlock" &&
                                    <Contact {...module} />
                                }

                                {module.Select_Collection === 'Area_Guides' &&
                                    <AreaGuides />
                                }

                                {module.Select_Collection === 'Reviews' &&
                                    <GoogleReviews />
                                }

                                {module.Select_Collection === 'Teams' &&
                                    <Peoples />
                                }

                                {module.Select_Collection === 'News' && NewsCategory === null &&
                                    <AllNews />
                                }
                                {module.Select_Collection === 'News' && NewsCategory !== null &&
                                    <News slug={NewsCategory.item} />
                                }

                                {module.Select_Collection === 'Videos' &&
                                    <Videos category={VideoCategory} />
                                }

                                {module.type === 'GLSTRAPI_ComponentModuleMilestones' &&
                                    <Milestones {...module} />
                                }

                                {renderComponent && module.Reviews_Background_Image &&
                                    <Reviews image={module.Reviews_Background_Image} id={props.page._id} imagetransforms={props.page.imagetransforms} />
                                }

                                {renderComponent && module.Social_Media_Cards &&
                                    <SocialMedia stats={module.Social_Media_Cards} page={props.page.Pagename} />
                                }

                                {renderComponent && module.Add_Stat &&
                                    <Counter stats={module.Add_Stat} page={props.page.Pagename} />
                                }

                                {module.Add_Service_Tile &&
                                    <ServiceTiles data={module.Add_Service_Tile} title={module.Service_Block_Title} id={props.page._id} imagetransforms={props.page.imagetransforms} />
                                }
                                {module.Select_Peoples &&
                                    <TeamSlider intro={module.Teams_Intro_Content} data={module.Select_Peoples} layout={props.page.Layout} />
                                }

                                {module.Latitude && module.Longtitude ?
                                    <Map lat={module.Latitude ? parseFloat(module.Latitude) : ''} lng={module.Longtitude ? parseFloat(module.Longtitude) : ''} height={"600px"} zoom={14} /> : ''}

                                {module.Service_Cards && module.Service_Cards[0].Image &&
                                    <ImageServiceCards data={module.Service_Cards} id={props.page._id} imagetransforms={props.page.imagetransforms} />
                                }

                            </>
                        )
                    })
                }
                {
                    props.page.Pagename !== "Home" &&
                    <Breadcrumb data={props.page.all_menus ? props.page.all_menus[0] : ''} page={props.page.Pagename} />
                }
            </div >
        )
    }
}

export default Modules