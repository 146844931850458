import { Link } from "@StarberryUtils";
import * as React from "react"
import HTMLReactParser from 'html-react-parser';
import { Button, Container, Row, Form } from 'react-bootstrap';
//import ScrollAnimation from "react-animate-on-scroll";


const WhiteBanner = (props) => {
	return (
      	<div className="static-top-banner plain-white-header">
	        <div className="image">
	          <Container className="pad-50">
		          <Row>
			          <div className="content col-md-12">
				          {props.content &&
			              <div animateIn='fadeInUp' animateOnce>
			                {HTMLReactParser(props.content.replace(/\n/g,'<br />'))}
			              </div>
			              }
			          </div>
		          </Row>
	          </Container>
	        </div>
		</div>
	)
}

export default WhiteBanner