import { useStaticQuery, graphql} from "gatsby"
import { Link } from "@StarberryUtils";
import * as React from "react"
import { useMatch } from "@reach/router"
import HTMLReactParser from 'html-react-parser';
import gql from "graphql-tag" 
import { useQuery } from '@apollo/client';

import PlayVideo from "../../Play/custom-video";
import { Button, Container, Form } from 'react-bootstrap';
// styles
//import ScrollAnimation from "react-animate-on-scroll";
import "./NewsHeader.scss"
// markup

import { VIDEOS_PAGE_URL } from "../../common/site/constants"; 

const VideosHeader = (props) => {
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      videoCategories {
        Name
        URL
      }
    }
  }
`);

  const video_categories = data.glstrapi.videoCategories
 

  var category = "All Videos";
  if (props.category !== null) {
    category = props.category.item
  }
 
  return (
    <React.Fragment>
      <div className="news-banner">
        <div className="banner">
          <Container className="pad-50 featured-properties">
            <div className="banner-text">
              {props.content &&
                <div animateIn='fadeInUp' animateOnce>
                  {HTMLReactParser(props.content.replace(/\n/g, '<br />'))}
                </div>
              }
              <div className="tabing-list-wrap">
                <ul className="tabing-list">
                  <li  className={`${category === 'All Videos' ? 'active' : ''}`}>
                    <Link to={`${VIDEOS_PAGE_URL.alias}`}>All Videos</Link>
                  </li>
                  {video_categories && video_categories.map((cat, index, active) => {
                    return (
                      <li className={`${category === cat.URL ? 'active' : ''}`}>
                        <Link to={`${VIDEOS_PAGE_URL.alias}/${cat.URL}`}  key={index}>{cat.Name}</Link>
                      </li>
                    )
                  })}
                 
                </ul>
              </div>    
            </div>

          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default VideosHeader