import { Link } from "@StarberryUtils";
import * as React from "react"
import HTMLReactParser from 'html-react-parser';
import { Button, Container, Row, Form } from 'react-bootstrap';
//import ScrollAnimation from "react-animate-on-scroll";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";


const StaticBanner = (props) => {

	const image_url = props.image.url

	let processedImages = JSON.stringify({});
	if (props.imagetransforms?.Banner_Image_Transforms) {
		processedImages = props.imagetransforms?.Banner_Image_Transforms;
	}
	console.log('static', props)

	return (
		<div className="static-top-banner">
			<div className="image">
				<ImageTransform imagesources={image_url} renderer="srcSet" imagename="articles.Banner_Image.full_details" attr={{ alt: `${props.image.alternativeText} - Dacha`, class: '' }} imagetransformresult={processedImages} id={props.id} />

				<Container className="pad-50">
					<Row>
						<div className="content static-page-banner-content">
							{props.content &&
								<>
									{HTMLReactParser(props.content.replace(/\n/g, '<br />'))}
								</>
							}
						</div>
					</Row>
				</Container>
			</div>
		</div>
	)
}

export default StaticBanner