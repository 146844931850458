import { Link } from "@StarberryUtils";
import * as React from "react"
import { useMatch } from "@reach/router"
import HTMLReactParser from 'html-react-parser';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';

import PlayVideo from "../../Play/custom-video";
import { Button, Container, Form } from 'react-bootstrap';
// styles
//import ScrollAnimation from "react-animate-on-scroll";
import "./NewsHeader.scss"
// markup

import { LATEST_NEWS_PAGE_URL } from "../../common/site/constants";
const GET_NEWS_MENUS = gql`
query GetNewsMenus{
  newsCategories(sort:"sort:asc", where:{Publish:true}) {
    Name
    URL
    id
  } 
}
`;

const NewsHeader = (props) => {

  const { loading, error, data } = useQuery(GET_NEWS_MENUS);

  var category = "All Posts";
  if (props.category !== null) {
    category = props.category.item
  }

  if (loading) return <p>Loading ...</p>;
  return (
    <React.Fragment>
      <div className="news-banner">
        <div className="banner">
          <Container className="pad-50 featured-properties">
            <div className="banner-text">
              {props.content &&
                <div animateIn='fadeInUp' animateOnce>
                  {HTMLReactParser(props.content.replace(/\n/g, '<br />'))}
                </div>
              }
              <div className="tabing-list-wrap">
                <ul className="tabing-list">
                  <li  className={`${category === 'All Posts' ? 'active' : ''}`}>
                    <Link to={`${LATEST_NEWS_PAGE_URL.alias}`}>All Posts</Link>
                  </li>
                  {data && data.newsCategories.map((news, index, active) => {
                    return (
                      <li className={`${category === news.URL ? 'active' : ''}`}>
                        <Link to={`${LATEST_NEWS_PAGE_URL.alias}/${news.URL}`}  key={index}>{news.Name}</Link>
                      </li>
                    )
                  })}
                 
                </ul>
              </div>    
            </div>

          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewsHeader